const local = {
  api: {
    COURSE_API: "https://dev-backend.teachingknowledgeloop.com/api/v1",
    COHORT_API: "https://dev-backend.teachingknowledgeloop.com/api/v1",
    FE_URL: "https://dev.teachingknowledgeloop.com",
  },
};

const dev = {
  api: {
    COURSE_API: "https://dev-backend.teachingknowledgeloop.com/api/v1",
    COHORT_API: "https://dev-backend.teachingknowledgeloop.com/api/v1",
    FE_URL: "https://dev.teachingknowledgeloop.com",
  },
};

const stg = {
  api: {
    COURSE_API: "https://stg-backend.teachingknowledgeloop.com/api/v1",
    COHORT_API: "https://stg-backend.teachingknowledgeloop.com/api/v1",
    FE_URL: "https://stg.teachingknowledgeloop.com",
  },
};

const uat = {
  api: {
    COURSE_API: "https://uat-backend.teachingknowledgeloop.com/api/v1",
    COHORT_API: "https://uat-backend.teachingknowledgeloop.com/api/v1",
    FE_URL: "https://uat.teachingknowledgeloop.com",
  },
};

const prod = {
  api: {
    COURSE_API: "https://prod-backend.teachingknowledgeloop.com/api/v1",
    COHORT_API: "https://prod-backend.teachingknowledgeloop.com/api/v1",
    FE_URL: "https://teachingknowledgeloop.com",
  },
};

const configureLogger = ({ log, info, debug, warn, trace, error }) => {
  if (!log) {
    console.log = () => {};
  }
  if (!info) {
    console.info = () => {};
  }
  if (!debug) {
    console.debug = () => {};
  }
  if (!warn) {
    console.warn = () => {};
  }
  if (!trace) {
    console.trace = () => {};
  }
  if (!error) {
    console.error = () => {};
  }
};

const getEnvironment = () => {
  let selectedEnvConfig = dev;
  let environment = process.env.REACT_APP_ENVIRONMENT || "dev";
  if (environment.trim() === "dev") {
    configureLogger({
      log: true,
      info: true,
      debug: true,
      warn: true,
      error: true,
      trace: true,
    });
    selectedEnvConfig = dev;
  } else if (environment.trim() === "staging") {
    configureLogger({
      log: true,
      info: true,
      debug: true,
      warn: true,
      error: true,
      trace: true,
    });
    selectedEnvConfig = stg;
  } else if (environment.trim() === "uat") {
    configureLogger({
      log: true,
      info: true,
      debug: true,
      warn: true,
      error: true,
      trace: true,
    });
    selectedEnvConfig = uat;
  } else if (environment.trim() === "prod") {
    configureLogger({
      log: false,
      info: false,
      debug: false,
      warn: false,
      error: true,
      trace: true,
    });
    selectedEnvConfig = prod;
  } else if (environment.trim() === "local") {
    configureLogger({
      log: true,
      info: true,
      debug: true,
      warn: true,
      error: true,
      trace: true,
    });
    selectedEnvConfig = local;
  }
  return selectedEnvConfig;
};

const config = getEnvironment();

export default { config };
